// vim: ts=2
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Typography, Stack, Box, Switch, IconButton, TablePagination, Button, Dialog, Paper, Grid, Menu, MenuItem, TextField } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp, Download } from "@mui/icons-material"; 
import { InStockHeader, InStockButton, InStockSubHeader } from "./../Components/Styled";
import SortingHelper from "./../Helpers/SortingHelper";
import DataTable from "./../Components/DataTable/DataTable";
import DefaultLayout from "./../Layouts/DefaultLayout";
import LoadingSpinner from "./../Components/LoadingSpinner/LoadingSpinner";
import TableToolsDialog from "./../Components/TableToolsDialog/TableToolsDialog";
import UserContext from "./../Context/UserContext";
import Dialogs from "./../Containers/Dialogs/Dialogs";
const SalesPage = (props) => {
	// state
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	const [items, setItems] = useState(null); // table data, this can be searched or filtered, etc ....
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [sort, setSort] = useState(null); // sorting state, clicking table headers manipulates this
	const [showActions, setShowActions] = useState(false);
	const [selectedHeader, setSelectedHeader] = useState(null);
	const [filterTerm, setFilterTerm] = useState(null);
	const [searchTerm, setSearchTerm] = useState(null);
	const [download, setDownload] = useState(false);
	const [fileToDownload, setFileToDownload] = useState(null);
	const [page, setPage] = useState(0);
	// context and hooks
	const context = useContext(UserContext);
	const navigate = useNavigate();
	const loading = items === null;
	const doExport = () => {	
		const mapper = (e, i) => {
			return {
				"name": e.name,
				"date_created": new Date(Date.parse(e.created_at)).toLocaleDateString(),
				"sales_count": e.sales_count,
				"category": e.category_name,
				"recipe_status": e.recipe_status
			};	
		};
		const headers = ["name", "date_created", "sales_count", "category",  "recipe_status"];
		const records = items.map(mapper);
		const payload = {
			"headers": headers,
			"records": records
		};
		const s = (response) => {
			const data = response.data;
			toast.success("Export generated, please click on the link below to download your file.");
			setFileToDownload(data);
			setDownload(true);
		};
		const e = (error) => {
			toast.error("Failed to generate data export, please contact support.");
		};
		context.api.getDataExport(context, s, e, payload);
	};
	const applySort = (headerName, sortState) => {
		const s = sortState[headerName];
		const sorting = new SortingHelper();
		const comparators = {
			"Name": ( a, b ) => { return s.mode === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name) },
			"Date Created": ( a, b ) => { return sorting.sortDates(a.created_at, b.created_at, s.mode) },
			"Total Sales": ( a, b ) => { return sorting.sortNumbers(a.sales_count, b.sales_count, s.mode) }
		};	
		const copy = [...context.tableData];
		copy.sort(comparators[headerName]);
		setItems(copy);
	};
	const onSortClicked = (event, headerName) => {
		const copy = {...sort};
		const sortable = Object.keys(copy);
		const s = sortable.find((x)=>{ return x === headerName });
		if(s === undefined){
			toast.info(`Error, column ${headerName} is not sortable.`);
			return;
		}
		const sortState = copy[headerName];
		if(!sortState.sorted){
			sortState.sorted = true;
			sortState.mode = "asc";
			setSort(copy);
			applySort(headerName, copy);
			return;
		}
		sortState.mode = sortState.mode === "asc" ? "desc" : "asc";
		setSort(copy);
		applySort(headerName, copy);
	};
	const onHeaderClicked = (event, headerName) => {
		const config = {
			searchable: ["Name"],
			filterable: ["Category", "Recipe Status"]
		};
		context.menuAnchor = event.target;
		const searching = config.searchable.find((x)=>{ return x === headerName });
		if(searching !== undefined){
			context.searchingTable = true;
			context.filteringTable = false;
			setSelectedHeader(headerName);
			return;
		}
		const filtering = config.filterable.find((x)=>{ return x === headerName });
		if(filtering !== undefined){
			context.filteringTable = true;
			context.searchingTable = false;
			setSelectedHeader(headerName);
			return;
		}
		context.filteringTable = false;
		context.searchingTable = false;
		setSelectedHeader(null);
	};
	const onFilterValueChanged = (filter, value) => {
		if(filter === "" || value === "" || value === "None"){
			setItems([...context.tableData]);
			return;
		}
		const mapping = {
			"Category": ( item, value ) => { return item.category_name === value },
			"Recipe Status": ( item, value ) => { return item.recipe_status === value }
		};	
		const f = mapping[filter];
		const matches = context.tableData.filter((x)=>{ return f(x, value)});
		setItems(matches);
	};
	const onSearchChanged = (phrase) => {
		// clearing the search phrase is the same as clearing search results
		if(phrase === ""){
			setItems([...context.tableData]);
			return;
		}
		const upper = phrase.toUpperCase();
		const matches = context.tableData.filter((x)=>{ return x.name.toUpperCase().indexOf(upper) !== -1 });
		setItems(matches);
	};
	const onLocationChanged = (location) => {
		setItems(null);
	};
	const onPageChanged = (event, page) => {
		setPage(page);
	};
	const toggleVariations = (id) => {
		const copy = [...items];
		const selected = copy.find((x)=>{ return x.id === id });
		if(selected === undefined){
			toast.error("Failed to find item");
			return;
		}
		selected.expanded = !selected.expanded;
		setItems(copy);
	};
	// possible to work on slice instead of all items??
	const getExpandedItems = (items) => {
		const expanded = [];
		for(let i = 0; i < items.length; i++){
			const j = items[i];
			// no variation
			// item doesn't need expansion
			if(j.item_variation_count <= 1){
				expanded.push(j);
				continue;
			}
			// user has not expanded this item
			if(j.expanded === false){
				expanded.push(j);
				continue;
			}
			const variations = j.variations;
			expanded.push(j);
			for(let k = 0; k < variations.length; k++){
				const v = variations[k];
				const o = {
					id: v.id,
					item_id: j.id,
					catalog_item_id: j.catalog_item_id,
					parent: j,
					expanded: false,
					key: `ITEM_VARIATION_${v.id}_ITEM_${j.id}`,
					created_at: v.variation.created_at,
					name: <p style={{paddingLeft:"45px"}}>{v.sales_item_name}</p>,
					sales_count: v.sales_count,
					category_name: j.category_name,
					activated: v.activated,
					recipe_status: v.recipe.status,
					variations: [],
					item_variation_count: 0,
					type: "ITEM_VARIATION"
				};
				expanded.push(o);
			}
		}
		return expanded;
	}; 
	const getItemName = (row) => {
		// get name of item variation
		if(row.type !== undefined && row.type === "ITEM_VARIATION"){
			return `${row.item_name} - ${row.name}`;
		}
		// one variation (is none valid???)
		// just use name of item and first variation
		if(row.variations !== undefined && row.variations.length === 1){
			return `${row.sales_item_name} - ${row.variations[0].sales_item_name}`;
		}
		// item contains more then one variation
		// allow item to be expanded
		return row.catalog_item.name;
	};
	const onRowClicked = (event, row) => {
		if(row.item_variation_count <= 1 && row.type === "ITEM"){
			viewItem(event, row.id);
			return;
		}
		// item has many variations
		// cannot view ...
		if(row.item_variation_count > 1 && row.type === "ITEM"){
			toast.info("Please view a variation of this item");
			return;
		}
		if(row.type === "ITEM_VARIATION"){
			viewItemVariation(event, row.item_id, row.id);
			return;
		}
	};
	const viewItemVariation = (event, itemId, variationId) => {
		const item = items.find((x)=>{ return x.type === "ITEM" && x.id === itemId});	
		const variation = item.variations.find((x)=>{ return x.id === variationId });
		if(variation === undefined){
			toast.error(`Failed to find item variation with id ${variationId} for item ${itemId}`);
			return;
		}
		context.selectedItem = item;
		context.selectedVariation = variation;
		if(!impersonate){
			navigate(`/sales-items/${itemId}/variations/${variationId}`);
			return;
		}else{
			navigate(`/accounts/impersonate/sales-items/${itemId}/variations/${variationId}`);
			return;
		}
	};
	const viewItem = (event, id) => {
		const selectedItem = items.find((x)=>{return x.id === id});	
		if(selectedItem === undefined){
			toast.error(`Error, failed to find item with id ${id}`);
			return;
		}
		context.selectedItem = selectedItem;
		if(!impersonate){
			navigate(`/sales-items/${id}`);
			return;
		}else{
			navigate(`/accounts/impersonate/sales-items/${id}`);
			return;
		}
	};
	// effects
	// loading items ...
	useEffect(()=>{
		if(items !== null){
			return;		
		}
		const s = (response) => {
			let data = response.data;
			data = data.map((e)=>{return{
				name: getItemName(e),
				activated: e.activated, 
				id: e.id, 
				key: `ITEM_${e.id}`,
				expanded: false,
				created_at: e.catalog_item.created_at,
				sales_count: e.sales_count,
				category_name: e.catalog_item.category_name,
				item_variation_count: e.variations.length,
				variations: e.variations,
				recipe: e.recipe,
				recipe_status: e.recipe.status,
				type: "ITEM",
				catalog_item_id: e.catalog_item.id
			}});
			// save a copy of the table data in the context
			// incase search phrases and/or filters are cleared
			context.tableData = data;
			// save state of data being viewed
			setItems(data);
			const sort = {
				"Name": { sorted: true, mode: "asc" },
				"Date Created": { sorted: false, mode: "asc" },
				"Total Sales": { sorted: false, mode: "asc" }
			};
			setSort(sort);
		};
		const e = (error) => {
			toast.error("Failed to get sales items from backend, please contact support.");
		};
		// setup is not complete
		// dont show items
		if(!context.setupComplete){
			setItems([]);
			return;
		}
		if(!impersonate){
			context.api.getSalesItems(context, s, e);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/sales-items`,
      "method": "GET"
		};
		context.api.doImpersonate(context, s, e, payload);
	});
	const getNameTableValue = (params) => {
		if(params.row.item_variation_count > 1){
			return ( 
				<span>
					<IconButton onClick={(event)=>{event.stopPropagation();toggleVariations(params.row.id);}} sx={{mr:"5px"}}>
						{params.row.expanded ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
					</IconButton>{params.row.name}
				</span>
			);
		}
		return params.row.name;
	};
	const getViewTableValue = (params) => {
		if(params.row.item_variation_count <= 1){
			return <Button variant="contained" color="success" onClick={(event)=>{viewItem(event, params.row.id);}}>View</Button>
		}
		// item has many variations
		// cannot view ...
		if(params.row.item_variation_count > 1 && params.row.type === "ITEM"){
			return null;
		}
		if(params.row.type === "ITEM_VARIATION"){
			return <Button variant="contained" color="success" onClick={(event)=>{viewItemVariation(event, params.row.item_id, params.row.id);}}>View</Button>
		}
	};
	const getUniqueItemCategories = (items) => {
		const categories = items.map((e)=>{ return e.category_name });
		let unique = categories.reduce((a,c)=>{if(a.find((x)=>{ return x === c}) === undefined){a.push(c);} return a; }, []);
		unique.push("None");
		return unique
	};
	const getFilters = (items) => {
		return { 
			"Category": getUniqueItemCategories(items),
			"Recipe Status": [ "Incomplete", "Complete", "None" ]
		};
	};
	let content = <LoadingSpinner message={"Loading items, please wait ..."} height={"100px"}/>;
	if(!context.setupComplete){
		content = (
			<Box sx={{textAlign:"center", borderRadius:"10px", border:"1px solid #CDCDCD", padding:"15px"}}>
				<Stack spacing={2}>
					<InStockHeader>Please complete your account setup before viewing stock items.</InStockHeader>
					<InStockSubHeader>To setup your account please visit the <InStockButton onClick={()=>{navigate('/settings');}}>settings</InStockButton> page.</InStockSubHeader>
				</Stack>
			</Box>
		);
	}
	if(!loading && context.setupComplete){
		const offset = page * itemsPerPage;
		let paginated = items.slice(offset, offset + itemsPerPage);
		paginated = getExpandedItems(paginated);
		// list of fields
		// determines what table headers are present 
		const fields = [
			{ field: "name", headerName: "Name", valueGetter: getNameTableValue },
			{ field: "created", headerName: "Date Created", valueGetter: (params) => { return new Date(Date.parse(params.row.created_at)).toLocaleDateString(); } },
			{ field: "sales_count", headerName: "Total Sales", valueGetter: (params) => { return params.row.sales_count; } },
			{ field: "category", headerName: "Category", valueGetter: (params) => { return params.row.category_name; } },
			{ field: "recipe_status", headerName: "Recipe Status" },
		];
		// handle searching and filtering
		const filters = getFilters(items);
		let menuAction = null;
		if(context.searchingTable === true){
			menuAction = (
				<Box sx={{padding:"15px", border:"1px solid #CDCDCD"}}>
					<TextField value={searchTerm} label="Search ..." size="small" 
						onChange={(event)=>{setSearchTerm(event.target.value);}} 
						onBlur={()=>{onSearchChanged(searchTerm);}} />
				</Box>
			);
		}
		if(context.filteringTable === true){
			menuAction = (	
				<Box sx={{padding:"15px", border:"1px solid #CDCDCD", width:"200px"}}>
					<TextField select 
							fullWidth
							value={filterTerm} 
							label="Filter by ..." 
							size="small" 
							onChange={(event)=>{onFilterValueChanged(selectedHeader, event.target.value);}}>
						{filters[selectedHeader].map((e,i)=>{ return <MenuItem key={e} value={e}>{e}</MenuItem> })}
					</TextField>
				</Box>
			);
		}
		content = (
			<Stack spacing={2}>
				<Menu open={selectedHeader !== null} 
						anchorEl={context.menuAnchor} 
						id="search-menu" 
						onClose={(event)=>{ context.filteringTable=false; context.searchingTable=false; setSelectedHeader(null); }}>
					{menuAction}
				</Menu>
				<Grid container>
					<Grid item sm={10}>
						<Typography variant={"h5"} sx={{color:"black", fontWeight:"bold", color:"#C12393"}}>Sales items at {context.selectedLocation.name}</Typography>
					</Grid>		
					<Grid item sm={2} sx={{textAlign:"right"}}>
						<IconButton onClick={doExport}>						
							<Download sx={{color:"black", fontSize:"2rem"}}/>
						</IconButton>
					</Grid>
				</Grid>
				<Typography sx={{fontSize:"20px", color:"#5E085A"}}>{items.length} RESULTS(S)</Typography>
				<Paper elevation={3} sx={{padding:"25px"}}>
				<Box sx={{width:"100%"}}>
					<DataTable 
						rows={paginated} 
						columns={fields} 
						onRowClicked={onRowClicked} 
						onHeaderClicked={onHeaderClicked} 
						sort={sort} 
						onSortClicked={onSortClicked} 
						filters={filters} />
				</Box>
				<Grid container sx={{marginTop:"15px"}}>
					<Grid item lg={8} sm={12} />
					<Grid item lg={4} sm={12} sx={{textAlign:"right"}}>
						<TablePagination component={Box} 
							count={items.length} 
							onPageChange={onPageChanged} 
							page={page} 
							rowsPerPage={itemsPerPage} 
							onRowsPerPageChange={(event)=>{setItemsPerPage(event.target.value);}} />
					</Grid>
				</Grid>
				</Paper>
			</Stack>
		);
	}
	const impersonatedContent = (
		<Box sx={{padding:"15px"}}>
			{content}
		</Box>
	);
	if(impersonate){
		return impersonatedContent;
	}
	return (
		<Dialogs download={download} fileToDownload={fileToDownload} onDownloadClosed={()=>{setDownload(false);}}>
			<DefaultLayout onLocationChanged={onLocationChanged}>
				{impersonatedContent}
			</DefaultLayout>	
		</Dialogs>
	);
};
export default SalesPage;
