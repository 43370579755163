import React, { useState, useEffect } from "react";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";
import InStockDialog from "./../InStockDialog/InStockDialog";
import { Box, Stack, Typography, Grid, Button } from "@mui/material";
const DownloadDialog = (props) => {
	const [link, setLink] = useState(null);
	useEffect(()=>{
		if(link !== null){
			return;
		}	
		const data = props.data;
		const binary = atob(data);
		const url = URL.createObjectURL(new Blob([binary]));
		setLink(url);
	});
	let content = <LoadingSpinner/>;
	if(link !== null){
		content = (
			<Box sx={{textAlign:"center"}}>
			<Stack spacing={3}>
				<Typography sx={{fontSize:"20px", fontWeight:"bold", color:"black"}}>Please click on the following link to download your file.</Typography>			
				<a href={link} download={"export.csv"}>Download</a>
				<Grid container>
					<Grid item lg={4}/>
					<Grid item lg={4} sm={12}>
						<Button variant={"contained"} className="pg" fullWidth size={"large"} sx={{color:"white"}} onClick={props.onCloseClicked}>Close</Button>	
					</Grid>
				</Grid>
			</Stack>
			</Box>
		);
	}
	return (
		<InStockDialog onCloseClicked={props.onCloseClicked} title={"Download"}>
			{content}
		</InStockDialog>	
	);
};
export default DownloadDialog;
