import React, { useState, useContext, useEffect } from "react";
import UserContext from "./../Context/UserContext";
import AdminLayout from "./../Layouts/AdminLayout";
import { Box, Grid, Paper } from "@mui/material";
import { CustomerCount, LocationCount, StockItemCount, SalesItemCount } from "./../Components/Widgets/Widgets";
const DashboardPage = (props) => {
	const context = useContext(UserContext);
	const widgets = [];
	// TODO configure widgets ??
	widgets.push(<CustomerCount key={"customers"} />);
	widgets.push(<LocationCount key={"locations"} />);
	widgets.push(<SalesItemCount key={"sales-items"} />);
	widgets.push(<StockItemCount key={"stock-items"} />);
	return (
		<AdminLayout>
			<Box sx={{padding:"25px"}}>
				<Paper sx={{padding:"25px"}} elevation={3}>
					<Grid container>
						{widgets}
					</Grid>
				</Paper>		
			</Box>
		</AdminLayout>	
	);
};
export default DashboardPage;
