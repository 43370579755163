// vim: ts=2
import './App.css';
import React from "react";
import { ToastContainer } from "react-toastify";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { Container } from "@mui/material";
import VerifyEmailPage from "./Pages/VerifyEmailPage";
import ForgotPasswordPage from "./Pages/ForgotPasswordPage";
import ResetPasswordPage from "./Pages/ResetPasswordPage";
import HomePage from "./Pages/HomePage";
import StockPage from "./Pages/StockPage";
import StockItemPage from "./Pages/StockItemPage";
import SalesPage from "./Pages/SalesPage";
import SalesItemPage from "./Pages/SalesItemPage";
import SettingsPage from "./Pages/SettingsPage";
import RegisterPage from "./Pages/RegisterPage";
import UsersPage from "./Pages/UsersPage";
import AccountsPage from "./Pages/AccountsPage";
import DashboardPage from "./Pages/DashboardPage";
import AdminSettingsPage from "./Pages/AdminSettingsPage";
import SquareAuthorizationPage from "./Pages/SquareAuthorizationPage";
import ImpersonateView from "./Components/ImpersonateView/ImpersonateView";
import "react-toastify/dist/ReactToastify.css";

const App = (props) => {
	return (
		<Container maxWidth={false} disableGutters={true} id={"AppContainer"}>
			<ToastContainer theme={"colored"} position={"top-center"} autoClose={5000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} />
			<BrowserRouter>
				<Routes>
					<Route path="/oauth/authorize" exact element={<SquareAuthorizationPage/>}/>
					<Route path="/" exact element={<HomePage />}/>		
					<Route path="/accounts" element={<AccountsPage/>}/>
					<Route path="/accounts/impersonate/:page" element={<ImpersonateView />}/>
					<Route path="/accounts/impersonate/:page/:itemId" element={<ImpersonateView /> }/>
					<Route path="/accounts/impersonate/:page/:itemId/:subpage/:variationId" element={<ImpersonateView /> }/>
					<Route path="/dashboard" exact element={<DashboardPage />}/>		
					<Route path="/verify-email" exact element={<VerifyEmailPage /> }/>
					<Route path="/forgot-password" exact element={<ForgotPasswordPage/>} />
					<Route path="/dashboard" exact element={<DashboardPage />}/>		
					<Route path="/verify-email" exact element={<VerifyEmailPage /> }/>
					<Route path="/forgot-password" exact element={<ForgotPasswordPage/>} />
					<Route path="/reset-password" exact element={<ResetPasswordPage/>} />
					<Route path="/users" exact element={<UsersPage />}/>		
					<Route path="/stock-items" exact element={<StockPage/>}/>
					<Route path="/stock-items/:itemId" exact element={<StockItemPage/>}/>
					<Route path="/sales-items" exact element={<SalesPage/>}/>
					<Route path="/sales-items/:itemId" exact element={<SalesItemPage variation={false} />}/>
					<Route path="/sales-items/:itemId/variations/:variationId" exact element={<SalesItemPage variation={true} />}/>
					<Route path="/settings" exact element={<SettingsPage/>}/>
					<Route path="/admin-settings" exact element={<AdminSettingsPage/>} />
					<Route path="/free-trial" exact element={<RegisterPage/>} />
				</Routes>
			</BrowserRouter>
		</Container>
	);
};
export default App;
