import React from "react";
export const units = {
	"weight": [
		{value: "g", label: "Grams (g)", units_of_measure: "weight", system_of_measure: "metric" }, 
		{value: "Kg", label: "Kilograms (Kg)", units_of_measure: "weight", system_of_measure: "metric" },
		{value: "oz", label: "Ounces (oz)", units_of_measure: "weight", system_of_measure: "imperial" }, 
		{value: "lb", label: "Pounds (lb)", units_of_measure: "weight", system_of_measure: "imperial" }
	], // weight units
	"volume": [
		{value: "L", label: "Litres (L)", units_of_measure: "volume", system_of_measure: "metric" }, 
		{value: "ml", label: "Millilitres (ml)", units_of_measure: "volume", system_of_measure: "metric" }, 
		{value: "fl.oz", label: "Fluid Ounces (fl.oz)", units_of_measure: "volume", system_of_measure: "imperial" }, 
		{value: "gal", label: "US Gallon (gal)", units_of_measure: "volume", system_of_measure: "imperial" }
	], // volume units
	"units": [
		{ value: "units", label: "Units", units_of_measure: "absolute", system_of_measure: "universal" } // absolute units
	]
};
