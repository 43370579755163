// vim: ts=2
import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import LoadingSpinner from "./../../LoadingSpinner/LoadingSpinner";
import UserContext from "./../../../Context/UserContext";
import AddIcon from "@mui/icons-material/Add";
import BinIcon from "@mui/icons-material/Delete";
import { InStockHeader, InStockSubHeader } from "./../../Styled";
import { Box, Container, Stack, Typography, 
	IconButton, Grid, ListItem, ListItemText, 
	FormControl, InputLabel, FilledInput, 
	InputAdornment, Paper } 
from "@mui/material";
const validator = require("validate.js");
const StockCategories = (props) => {
	const [categories, setCategories] = useState(null);
	const [category, setCategory] = useState(null);
	const [errors, setErrors] = useState(undefined);
	const context = useContext(UserContext);
	const loading = categories === null;
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	const handleDeleteItem = (itemId) => {
		const s = (response) => {
			toast.success("Success, category deleted.");
			setCategory("");
			setCategories(null);
			if(props.onCategoryDeleted !== undefined){
				props.onCategoryDeleted(itemId);
			}
		};
		const e = (error) => {
			toast.error("Failed to delete category, please contact support.");
		};
		if(!impersonate){
			context.api.deleteCategory(context, s, e, itemId);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/stock-categories/${itemId}`,
      "method": "DELETE"
		};
		context.api.doImpersonate(context, s, e, payload);
	};
	useEffect(()=>{
		if(categories !== null){
			return;
		}
		const s = (response) => {
			const data = response.data;
			setCategories(data);
		};
		const e = (error) => {
			toast.error("Failed to load categories, please contact support.");
		};
		if(!impersonate){
			context.api.getCategories(context, s, e);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/stock-categories`,
      "method": "GET"
		};
		context.api.doImpersonate(context, s, e, payload);
	});
	const addCategory = () => {
		const constraints = {
			name: { presence: { allowEmpty: false }, length: { maximum: 255 } }
		};
		const data = {
			"name": category,
			"location_id": context.selectedLocation.id
		};
		setErrors(undefined);
		const errors = validator.validate(data, constraints);
		if(errors !== undefined){
			setErrors(errors);
			return;
		}
		const s = (response) => {
			const cat = response.data;
			toast.success("Success, category added.");
			setCategory("");
			setCategories(null);
			if(props.onCategoryAdded !== undefined){
				props.onCategoryAdded(cat);
			}
		};
		const e = (error) => {
			toast.error("Failed to add category, please contact support.");
		};
		if(!impersonate){
			context.api.addCategory(context, s, e, data);	
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/stock-categories`,
      "method": "POST",
			"data": JSON.stringify(data)
		};
		context.api.doImpersonate(context, s, e, payload);
	};
	let content = <LoadingSpinner />;
	if(!loading){
		if(categories.length === 0){
			content = (
				<Box>
					<InStockSubHeader>
						No categories exist, please add one below.
					</InStockSubHeader>
				</Box>
			);
		}
		if(categories.length > 0){
			const items = categories.map((e)=>{ return <ListItem sx={{borderBottom:"2px solid black"}} key={e.id} 
					secondaryAction={<IconButton onClick={(event)=>{handleDeleteItem(e.id)}}edge="end" aria-label="delete" sx={{color:"black"}}><BinIcon/></IconButton>}>
				<ListItemText primary={e.name} />
			</ListItem>});
			content = (
				<Stack spacing={3}>
					{items}
				</Stack>
			);
		}
	}
	return (
		<Paper elevation={2} className="instock-view">
			<Stack spacing={2}>
				<InStockHeader sx={{textAlign:"center"}}>Stock Categories</InStockHeader>
				{content}
				<FormControl sx={{ m: 1, width: "100%" }} variant="filled">
          <InputLabel htmlFor="create-category">Create Category</InputLabel>
          <FilledInput
            id="create-category"
            type={"text"}
            value={category}
            onChange={(event)=>{setCategory(event.target.value)}}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="add category"
                  onClick={addCategory}
                  edge="end"
                >
									<AddIcon/>
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
			</Stack>
		</Paper>
	);
};
export default StockCategories;
