// vim: ts=2
import React, { useState, useEffect, useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import BinIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import AddStockDialog from "./../AddStockDialog/AddStockDialog";
import { toast } from "react-toastify";
import { units as ConstantUnits } from "./../../constants";
import { List,
	ListItem,
	ListItemText,
	Container, 
	Typography, 
	Stack,
	Box, 
	Switch,
	IconButton,
	Paper,
	Grid,
	FormGroup,
	FormControlLabel, 
	TextField,
	MenuItem, 
	InputAdornment,
	Dialog,
	Button } from "@mui/material";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";
import UserContext from "./../../Context/UserContext";

export const IngredientList = (props) => {
	
	// constants
	const CREATE_NEW_STOCK_ITEM = -999;
	// props
	const ingredients = props.ingredients;
	const units = props.units;
	const stockItems = props.stockItems;
	// state
	const [selectedItem, setSelectedItem] = useState(-1);
	const [ingredientQuantity, setIngredientQuantity] = useState("");
	const [ingredientUnits, setIngredientUnits] = useState("");
	// event handlers 
	const onIngredientChanged = (event) => {
		const value = event.target.value;
		if(value === CREATE_NEW_STOCK_ITEM){
			props.onAddStockItem();
			return;
		}
		const ingredient = stockItems.find((x)=>{ return x.id === value });
		if(ingredient === undefined){
			setSelectedItem(-1);
			return;
		}
		setSelectedItem(ingredient.id);	
	};
	const addIngredient = (event) => {
		if(selectedItem === -1){
			toast.info("Please select an ingredient first");
			return;
		}	
		const stockItem = stockItems.find((x)=>{ return x.id === selectedItem });
		const ingredient = { 
			"name": stockItem.name,  // required for frontend, not backend
			"stock_item_id": stockItem.id, // id of stock item
			"quantity": ingredientQuantity * 1, // amount of this ingredient
			"quantity_units": ingredientUnits,  // units of the amount
			"units_of_measure": stockItem.units_of_measure
		};
		setSelectedItem(-1);
		props.onIngredientSaved(event, ingredient);
	};
	let ingredientSelect = (
		<Grid item xs={12} lg={12}>
			<LoadingSpinner />
		</Grid>
	);
	// if stock has loaded ...
	if(stockItems !== null){
		let ingredientOptions = stockItems.map((e,i)=>{ return {name: e.name, id: e.id}}); 
		ingredientOptions = ingredientOptions.map((e,i)=>{ return <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>});
		const pleaseSelect = <MenuItem key={-1} value={-1}>Please select an option...</MenuItem>;
		const createNew = <MenuItem key={CREATE_NEW_STOCK_ITEM} value={CREATE_NEW_STOCK_ITEM}><AddIcon sx={{mr:"5px"}}/>New stock item</MenuItem>;
		ingredientOptions = [ pleaseSelect, createNew, ...ingredientOptions ];
		ingredientSelect = (
			<Grid item xs={4}>
				<TextField 
					size={"normal"}
					select 
					value={selectedItem} 
					id="stock-items" 
					label="Available ingredients" 
					onChange={onIngredientChanged}
					fullWidth>
					{ingredientOptions}
				</TextField>
			</Grid>
		);
	}
	let unitsOfMeasure = null;
	if(selectedItem !== -1){
		const selectedStockItem = stockItems.find((x)=>{ return x.id === selectedItem });
		unitsOfMeasure = selectedStockItem.units_of_measure;
	}
	return (
		<Box sx={{mb:"15px"}}>
			<Grid container>
				<Grid item xs={12}>
					<List>
						{ingredients.map((e,i)=>{ return <IngredientItem 
							key={`ingredient_${e.id}`} 
							units={units.filter((x)=>{ return x.units_of_measure === e.units_of_measure })} 
							ingredient={e} 
							onIngredientEdited={props.onIngredientEdited} 
							onIngredientSaved={props.onIngredientSaved} 
							onIngredientDeleted={props.onIngredientDeleted}/> })}
						<ListItem>
							<Grid container>
								{ingredientSelect}
								<Grid item xs={8} sx={{paddingLeft:"16px"}}>
									<Grid container spacing={2}>
										<Grid item xs={5}>
											<TextField 
												fullWidth
												onChange={(event)=>{setIngredientQuantity(event.target.value);}}
												value={ingredientQuantity}
												size="normal" 
												label="Quantity" />
										</Grid>
										<Grid item xs={5}>
											<TextField 
												fullWidth
												onChange={(event)=>{setIngredientUnits(event.target.value);}}
												value={ingredientUnits}
												select
												size="normal"
												label="Units">
													{units.filter((x)=>{ return x.units_of_measure === unitsOfMeasure }).map((e,i)=>{ return <MenuItem key={`unit_${i}`} value={e.value}>{e.label}</MenuItem>})}
											</TextField>
										</Grid>
										<Grid item xs={2} sx={{textAlign:"center"}}>
											<IconButton fullWidth disabled={stockItems === null} variant="contained" size={"large"} onClick={addIngredient}>
												<AddIcon sx={{color:"black", fontSize:"2rem"}}/>
											</IconButton>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</ListItem>
					</List>
				</Grid>
			</Grid>
    </Box>
	);
};

export const IngredientItem = (props) => {
	const [ingredient, setIngredient] = useState(props.ingredient);
	const [dirty, setDirty] = useState(false);
	const [quantity, setQuantity] = useState(ingredient.quantity);
	const [unit, setUnit] = useState(ingredient.quantity_units);
	const units = props.units;
	const doQuantityChanged = (event) => {
		const value = event.target.value;
		const i = {...ingredient};
		i.quantity = value * 1;
		setIngredient(i);
		setQuantity(value);
		setDirty(true);
	};
	const doUnitChanged = (event) => {
		const value = event.target.value;
		const i = {...ingredient};
		i.quantity_units = value;
		setIngredient(i);
		setUnit(value);
		setDirty(true);
	};
	return (
		<ListItem sx={{padding:"16px"}}>
			<Grid container>
				<Grid item xs={4}>
					<ListItemText secondary={ingredient.category} primary={ingredient.name} />			
				</Grid>
				<Grid item xs={8} sx={{paddingLeft:"16px"}}>
					<Grid container spacing={2}>
						<Grid item xs={5}>
							<TextField 
								fullWidth
								size="normal" 
								value={quantity}	
								onChange={doQuantityChanged} 
								label="Quantity" 
								onBlur={(event)=>{if(dirty){props.onIngredientEdited(event, ingredient);setDirty(false);}}} />
						</Grid>
						<Grid item xs={5}>
							<TextField 
								fullWidth
								select
								size="normal"
								value={unit}
								onChange={doUnitChanged}
								label="Units"
								onBlur={(event)=>{if(dirty){props.onIngredientEdited(event, ingredient);setDirty(false);}}} >
									{units.map((e,i)=>{ return <MenuItem key={`unit_${i}`} value={e.value}>{e.label}</MenuItem>})}
							</TextField>
						</Grid>
						<Grid item xs={2} sx={{textAlign:"center"}}>
							<IconButton size={"large"} onClick={(event)=>{props.onIngredientDeleted(event, ingredient);}}>
								<BinIcon sx={{color:"black", fontSize:"2rem"}}/>
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</ListItem>
	);
};

export const RecipeCard = (props) => {
	const recipe = props.recipe;
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	// TODO
	// consider adding ordinal
	const ingredientMapper = (e, i) => {
		return { 
 			id: e.id, 
  		name: e.stock_item.name, 
  		stock_item_id: e.stock_item_id, 
  		quantity: e.quantity * 1, 
  		quantity_units: e.quantity_units,
			category: e.stock_item.category.name,		
			units_of_measure: e.stock_item.units_of_measure
		};
	};
	const context = useContext(UserContext);
	const [ingredients, setIngredients] = useState(recipe.ingredients.map(ingredientMapper));
	const [stockItems, setStockItems] = useState(null);
	const [stockDialog, setStockDialog] = useState(false);
	useEffect(()=>{
		if(stockItems !== null){
			return;
		}
		const s = (response) => {
			const data = response.data;
			setStockItems(data);
		};
		const e = (error) => {
			toast.error("Failed to load stock items. Please contact support.");
			setStockItems([]);
		};
		if(!impersonate){
			context.api.getStockItems(context, s, e);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/stock-items`,
      "method": "GET"
		};
		context.api.doImpersonate(context, s, e, payload);
	});
	const removeIngredient = (i) => {
		const copy = [...ingredients];
		const index = copy.findIndex((x)=>{ return x.stock_item_id === i.stock_item_id });
		copy.splice(index, 1);
		setIngredients(copy);
	};
	const onIngredientDeleted = (event, i) => {
		// if the ingredient has an id this means the ingredient
		// has been persisted in the backend
		// and needs to be deleted
		if(i.id !== undefined){
			const s = (response) => {
				toast.success("Ingredient deleted.");
				removeIngredient(i);
			};
			const e = (error) => {
				toast.error("Failed to delete ingredient, please contact support.");
			};
			if(!impersonate){
				context.api.deleteIngredient(context, s, e, recipe.id, i.id);
				return;
			}
			const payload = {
				"user_id": context.impersonatedUserId,
				"url": `/api/users/${context.impersonatedUserId}/recipes/${recipe.id}/ingredients/${i.id}`,
      	"method": "DELETE"
			};
			context.api.doImpersonate(context, s, e, payload);
		}
		// just remove from the frontend
		removeIngredient(i);
		toast.success("Ingredient deleted.");
	};
	const onIngredientEdited = (event, i) => {
		const s = (response) => {
			const data = response.data;
			setIngredients(data.ingredients.map(ingredientMapper));
			toast.success("Ingredient updated.");
		};
		const e = (error) => {
			toast.error("Failed to update ingredient, please contact support.");
		};
		if(!impersonate){
			context.api.updateIngredient(context, s, e, recipe.id, i.id, i);	
			return;
		}
		const impersonated = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/recipes/${recipe.id}/ingredients/${i.id}`,
      "method": "PUT",
			"data": JSON.stringify(i)
		};
		context.api.doImpersonate(context, s, e, impersonated);
	};
	const onIngredientSaved = (event, i) => {
		const s = (response) => {
			const data = response.data;
			setIngredients(data.ingredients.map(ingredientMapper));
			toast.success("Ingredient saved");
		};
		const e = (error) => {
			toast.error("Failed to add ingredient to recipe, please contact support.");	
		};
		if(!impersonate){
			context.api.addIngredient(context, s, e, recipe.id, i);	
			return;
		}
		const impersonated = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/recipes/${recipe.id}/ingredients`,
      "method": "POST",
			"data": JSON.stringify(i)
		};
		context.api.doImpersonate(context, s, e, impersonated);
	};
	const addStockItem = (event) => {
		setStockDialog(true);
	};
	let ingredientList = (
		<Typography variant="body1" sx={{color:"black", textAlign:"center", paddingTop:"25px",paddingBottom:"25px", fontSize:"20px"}}>
   		Please activate your recipe to start adding ingredients.
    </Typography>
	);
	const units = Object.keys(ConstantUnits).reduce((a,c)=>{const items = ConstantUnits[c]; return [...a, ...items];}, []);
	if(ingredients.length >= 0){
		let helpMsg = null;
		if(ingredients.length === 0){
			helpMsg = (
				<Typography variant="body1" sx={{color:"black", textAlign:"center", paddingTop:"25px", paddingBottom:"25px", fontSize:"20px"}}>
					Your recipe doesn't contain any ingredients, please add an ingredient.
    		</Typography>
			);
		}
		ingredientList = (
			<IngredientList 
				ingredients={ingredients} 
				onAddStockItem={addStockItem}
				units={units} 
				stockItems={stockItems} 
				onIngredientSaved={onIngredientSaved}
				onIngredientEdited={onIngredientEdited}
				onIngredientDeleted={onIngredientDeleted} />
		); 
	}
	return (
		<Box sx={{paddingTop:"18px"}}>
			<Dialog open={stockDialog} onClose={(event)=>{setStockDialog(false);}} PaperProps={{backgroundColor:"red"}}>
				<AddStockDialog impersonate={impersonate} onCloseClicked={(event)=>{setStockDialog(false);}} onStockItemAdded={(event)=>{setStockItems(null);}}/>
			</Dialog>
			<Stack spacing={1}>
				<Typography sx={{color:"#5E085A", fontSize:"20px"}}>RECIPE</Typography>	
				<Paper sx={{padding:"25px"}} elevation={3}>
					{ingredientList}
				</Paper>
			</Stack>
		</Box>	
	);
};
