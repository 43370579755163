import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Typography, Dialog, Paper, Grid, Stack, TextField } from "@mui/material";
import { InStockHeader, InStockSubHeader, InStockButton, ErrorText } from "./../Components/Styled";
import InStockDialog from "./../Components/InStockDialog/InStockDialog";
import InStockClient from "./../Api/InStockClient";
import LoadingSpinner from "./../Components/LoadingSpinner/LoadingSpinner";
import UserContext from "./../Context/UserContext";
import FormHelper from "./../Helpers/FormHelper"; 
const ForgotPasswordPage = (props) => {
	const location = useLocation();
	// context
	const context = useContext(UserContext);
	const navigate = useNavigate();
	const forms = new FormHelper();
	const constraints = {
		"password": { presence: { allowEmpty: false} },
		"password_confirmation": { presence: { allowEmpty: false}, equality: "password" },
	};
	// state
	const [password, setPassword] = useState("");
	const [passwordConfirmation, setPasswordConfirmation] = useState("");
	const [errors, setErrors] = useState({});
	// events
	const onSubmitClicked = (event) => {
		let query = location.search;
		query = query.substring(1);
		const tokens = query.split("&");
		const userId = tokens[0].split("=")[1];
		const state = tokens[1].split("=")[1];
		const payload	= {
			"password": password,
			"password_confirmation": passwordConfirmation,
			"user_id": userId * 1,
			"state": state
		};
		setErrors({});
		const errors = forms.validateAll(constraints, payload);
		if(errors !== undefined){
			toast.error("Please fix the errors highlighted in red.");
			setErrors(errors);
			return;
		}
		const s = (response) => {
			navigate(`/?message=Your password has been reset.`);
		};
		const e = (error) => {
			toast.error("Somethings gone wrong, please try again.");
		};
		InStockClient.getInstance(null).doResetPassword(payload, s, e);
	};
	const validateConfirmation = () => {
		const payload = {
			"password": password,
			"password_confirmation": passwordConfirmation
		};	
		let copy = {...errors};
		delete copy["password_confirmation"];
		setErrors(copy);
		copy = forms.validateAll(constraints, payload);
		if(copy !== undefined && copy["password_confirmation"] !== undefined){
			delete copy["password"];
			setErrors({...copy, ...errors});
		}
	};
	const validateSingle = (field, value) => {
		let copy = {...errors};
		delete copy[field];
		setErrors(copy);
		copy = forms.validateSingle(constraints, field, value);
		if(copy !== undefined){
			setErrors({...errors, ...copy});
		}
	};
	const onPasswordChanged = (event) => {
		const value = event.target.value;
		setPassword(value);
	};
	const onPasswordConfirmationChanged = (event) => {
		const value = event.target.value;
		setPasswordConfirmation(value);
	};
	const getHasError = (field) => {
		return errors[field] !== undefined;	
	};
	const getHelperText = (field) => {
		if(getHasError(field)){
			return errors[field].map((e,i)=>{ return <ErrorText key={`${field}_error_${i}`}>{e}</ErrorText>});
		}
		const mapping = {
			"password": "Your password must contain one upper case and lower case letter, one digit and one special character.",
			"password_confirmation": "Please confirm your password.",
		};
		return mapping[field];
	};
	// effects
	// content
	let content = (
		<Stack spacing={2}>
			<InStockHeader sx={{textAlign:"center"}}>Reset Password</InStockHeader>
			<InStockSubHeader sx={{textAlign:"center"}}>Please enter and confirm a new password for your account.</InStockSubHeader>
			<TextField 
				fullWidth
				type={"password"}
				error={getHasError("password")} 
				helperText={getHelperText("password")}
				onBlur={(event)=>{validateSingle("password", password);}}
				size="large" 
				label="Password" 
				value={password} 
				onChange={onPasswordChanged} />
			<TextField 
				fullWidth
				type={"password"}
				error={getHasError("password_confirmation")} 
				helperText={getHelperText("password_confirmation")}
				onBlur={(event)=>{validateConfirmation();}}
				size="large" 
				label="Confirm Password" 
				value={passwordConfirmation} 
				onChange={onPasswordConfirmationChanged} />
			<Grid container>
				<Grid item lg={4}/>
				<Grid item lg={4} xs={12}>
					<InStockButton onClick={onSubmitClicked} size={"large"} fullWidth>Submit</InStockButton>
				</Grid>
			</Grid>
		</Stack>
	);
	const logo = <img src={InStockClient.getInstance(null).getPublicLogoURL()} style={{height:"1.5em", width:"auto"}} alt={"InStockLogo"} />;
	return (
		<Dialog maxWidth="sm" fullWidth open={true}>
			<InStockDialog title={logo} enableClose={false}>
				{content}
			</InStockDialog>
		</Dialog>
	);
};
export default ForgotPasswordPage;
