// vim: ts=2
import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import UpIcon from "@mui/icons-material/KeyboardArrowUp";
import { toast } from "react-toastify";
import InStockLogo from "./../Assets/Images/instock.png";
import StockSvg from "./../Assets/Images/stock.svg";
import DashSvg from "./../Assets/Images/dash.svg";
import CogSvg from "./../Assets/Images/cog.svg";
import CartSvg from "./../Assets/Images/cart.svg";
import InStockClient from "./../Api/InStockClient";
import LoadingSpinner from "./../Components/LoadingSpinner/LoadingSpinner";
import PortalSelect from "./../Components/PortalSelect/PortalSelect";
import InStockLink from "./../Components/InStockLink/InStockLink";
import ChevronDownSvg from "bootstrap-icons/icons/chevron-down.svg";
import ChevronUpSvg from "bootstrap-icons/icons/chevron-up.svg";
import Dialogs from "./../Containers/Dialogs/Dialogs";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useNavigate } from "react-router-dom";
import UserContext from "./../Context/UserContext";
import { ShoppingCart, Inventory, Settings, AccountCircle } from "@mui/icons-material";
import { 
	useMediaQuery, Container, Stack, 
	AppBar, Toolbar, Box, Button, 
	Typography, Grid, TextField, 
	MenuItem, Avatar, IconButton, 
	Tooltip, Menu 
} from "@mui/material";

const DefaultLayout = (props) => {

	const context = useContext(UserContext);
	const navigate = useNavigate();
	const [locations, setLocations] = useState(context.locations);
	const [selectedLocation, setSelectedLocation] = useState(context.locations !== null ? context.selectedLocation : null);
	const [accountSettingsAnchor, setAccountSettingsAnchor] = useState(null);
	const [user, setUser] = useState(null);
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	// dialogs ....
	const [takeStock, setTakeStock] = useState(false);
	const [receiveStock, setReceiveStock] = useState(false);
	const [profile, setProfile] = useState(false);
	const [reportUsage, setReportUsage] = useState(false);
	const [freeTrialAlert, setFreeTrialAlert] = useState(context.freeTrialAlert);
	const tablet = useMediaQuery("(max-width: 1024px)");
	// logout 
	// doesn't really matter if an error happens here
	// just means a stray token will get cleaned up later
	const doLogout = () => {
		if(impersonate){
			toast.info("Please logout from the admin view.");
			return;
		}
		const client = InStockClient.getInstance(context.token);
		const s = (response) => {
			context.userId = null;
			context.token = null;
			context.locations = null;
			context.freeTrialAlert = false;
			navigate("/?message=You've been logged out.");
		};
		const e = (error) => {
			context.userId = null;
			context.freeTrialAlert = false;
			context.token = null;
			context.locations = null;
			navigate("/?message=You've been logged out.");
		};
		client.logout(s, e);
	};
	// make sure that the user is logged in
	useEffect(()=>{
		if(context.token === null || 
				context.userId === null || 
				context.token === undefined){
			navigate("/");
			return;
		}
	});
	// load user profile
	useEffect(()=>{	
		if(user !== null){
			return;
		}
		const s = (response) => {
			const data = response.data;
			// may as well store user in context
			// for convenience ...
			context.user = data;
			setUser(data);
		};
		const e = (error) => {
			toast.error("Failed to load user profile, please contact support.");
		};
		if(!impersonate){
			context.api.getUser(context, s, e);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}`,
      "method": "GET"
		};
		context.api.doImpersonate(context, s, e, payload);
	});
	// notify of free trial status
	useEffect(()=>{
		if(user === null){
			return;
		}
		if(freeTrialAlert){
			return;
		}
		// alert user of their expired trial 
		if(user.free_trial && user.free_trial_expired){
			toast.warn("Your free trial has expired, please subscribe to continue using instock.");
			context.freeTrialAlert = true;
			setFreeTrialAlert(true);
		}
	});
	const onLocationChanged = (id) => {
		const selected = locations.find((l)=>{ return l.id === id; });
		if(selected === undefined){
			throw new Error("Location is undefined after selected.");
		}
		context.selectedLocation = selected;
		context.stockCategories = selected.stock_categories;
		context.stocktakingLocations = selected.stocktaking_locations;
		setSelectedLocation(selected);
		// notify other parties about the change of location
		// pretty much every page will need to listen for changes in location
		// the location thaat was selected doesn't really need to be passed as a parameter
		// as its available in the context ...
		if(props.onLocationChanged !== undefined){
			props.onLocationChanged(selected);
		}
	};
  const getClassName = (view) => {
		const l = window.location.pathname.substring(1);
    return view === l ? "InStockLink InStockLinkSelected" : "InStockLink";
  };
	const doTakeStock = () => {	
		setTakeStock(true);
	};
	const doReceiveStock = () => {
		setReceiveStock(true);
	};
	const doReportUsage = () => {
		setReportUsage(true);
	};
	const doProfile = () => {
		setProfile(true);
	};
	const onNavigationClicked = (item) => {
		switch(item){
			case "sales-items":
				navigate("/sales-items");
				break;
		}	
	};
	const accountSettingsOpen = accountSettingsAnchor !== null;
	let chevronIcon = accountSettingsOpen ? "bi-chevron-up" : "bi-chevron-down";
	let portalSelect = (
		<Box sx={{backgroundColor:"white", borderRadius:"33px 33px 33px 33px", padding:"5px"}}>
			<LoadingSpinner message={null} height="50px"/>
		</Box>
	);
	if(!context.setupComplete){
		portalSelect = (
			<Box sx={{backgroundColor:"white", borderRadius:"33px 33px 33px 33px", padding:"5px", textAlign:"center"}}>
				<Typography sx={{fontSize:"1em", color:"black"}}>Please complete setup</Typography>
			</Box>
		);
	}
	if(locations !== null && context.setupComplete){
		const opts = locations.map((e,i)=>{ return {key: e.id, value: e.id, text: e.name}});
		const selected = context.selectedLocation.id;
		portalSelect = <PortalSelect options={opts} selected={selected} onChange={onLocationChanged}/>;
	}
	// menu items
	// the appearance of these changes per device used
	// tablets and mobile devices have a more stacked look
	let salesItems = (
		<span style={{verticalAlign:"middle"}}>
			<ShoppingCart sx={{color:"white", fontSize:"2.5rem", verticalAlign:"middle", mr:"15px"}} />
			Sales Items
		</span>
	);
	let stockItems = (
		<span style={{verticalAlign:"middle"}}>
			<Inventory sx={{color:"white", fontSize:"2.5rem", verticalAlign:"middle", mr:"15px"}}/>
			Stock Items
		</span>
	);
	let settings = (
		<span style={{verticalAlign:"middle"}}>
			<Settings sx={{color:"white", fontSize:"2.5rem", verticalAlign:"middle", mr:"15px"}}/>
			Settings
		</span>
	);
	if(tablet){
		salesItems = (
			<Stack spacing={1} alignItems={"left"}>
				<ShoppingCart sx={{color:"white", fontSize:"2.5rem", verticalAlign:"middle"}} />
				<span style={{display:"block"}}>Sales<br/>Items</span>
			</Stack>
		);
		stockItems = (
			<Stack spacing={1} alignItems={"left"}>
				<Inventory sx={{color:"white", fontSize:"2.5rem", verticalAlign:"middle"}} />
				<span style={{display:"block"}}>Stock<br/>Items</span>
			</Stack>
		);
		settings = (
			<Stack spacing={1} alignItems={"left"}>
				<Settings sx={{color:"white", fontSize:"2.5rem", verticalAlign:"middle"}} />
				Settings
			</Stack>
		);
	}
	return (
		<Dialogs 
			impersonate={impersonate}
			profile={profile}
			receiveStockDraft={receiveStock} 
			takeStockDraft={takeStock} 
			reportUsage={reportUsage}
			onReportUsageClosed={()=>{setReportUsage(false);}}
			onReportUsage={()=>{setReportUsage(false);}}
			onProfileClosed={()=>{setProfile(false);}}
			onTakeStockClosed={()=>{setTakeStock(false);}}
			onReceiveStockClosed={()=>{setReceiveStock(false);}}
			onReceiveStockDraftClosed={()=>{setReceiveStock(false);}} 
			onTakeStockDraftClosed={()=>{setTakeStock(false);}}
			onDraftFinalised={props.onDraftFinalised}>
		<Grid container>
			<Grid className="pg bs" item md={2} sx={{padding:"15px", height:window.innerHeight}}>
				<Box sx={{height:"100%", backgroundColor:"rgba(255,255,255,0.1)", opacity:1}}>
				<Box sx={{textAlign:"left", padding:"15px"}}>
					<Stack spacing={3}>
						<img src={InStockLogo} height="auto" width="100%"/>
						<Typography variant="h6" element="p" sx={{fontWeight:"bold", color:"white", textAlign:"center"}}>Your Portal</Typography>
						{portalSelect}
					</Stack>
				</Box>
				<Box sx={{textAlign:"left", paddingLeft:"15px", paddingBottom:"15px"}}>
					<Stack spacing={tablet ? 1 : 3}>
						<Link className={getClassName("sales-items")} 
								to={impersonate ? "/accounts/impersonate/sales-items" : "/sales-items"} 
								onClick={()=>{context.navigation = "sales-items";}}>
							{salesItems}
						</Link>
						<Link className={getClassName("stock-items")} 
								to={impersonate ? "/accounts/impersonate/stock-items" : "/stock-items"} 
								onClick={()=>{context.navigation = "stock-items";}}>
							{stockItems}
						</Link>
						<Link to={impersonate ? "/accounts/impersonate/settings" : "/settings"} 
								className={getClassName("settings")} 
								onClick={(event)=>{context.navigation = "settings";}}>
							{settings}
						</Link>
					</Stack>
				</Box>
				</Box>
			</Grid>
			<Grid item md={10}>
				<Stack>
					<Box className="pb" sx={{flexGrow:1, width:"100%", paddingTop:"10px", paddingBottom:"10px"}}>
							<Box sx={{backgroundColor:"white", width:"130px", height:"auto", float:"right", borderRadius:"50px 0px 0px 50px"}}>
								<IconButton disabled sx={{cursor: "arrow"}}>
									<Avatar src={user !== null ? user.profile_picture_data_uri : undefined} sx={{width:"62px", height:"62px", bgcolor:"gray"}}>
										<AccountCircle sx={{fontSize:"5rem"}} />
									</Avatar>
								</IconButton>
								<IconButton onClick={(event)=>setAccountSettingsAnchor(event.currentTarget)} 
										size="medium" aria-haspopup="true"
										aria-expanded={accountSettingsOpen ? "true" : undefined} 
										area-controls={accountSettingsOpen ? "account-menu" : undefined}>
									<i className={`bi ${chevronIcon}`} style={{color:"#5E085A"}}></i>
								</IconButton>
							</Box>
					</Box>
					<Menu open={accountSettingsOpen} anchorEl={accountSettingsAnchor} id="account-menu" onClose={(event)=>setAccountSettingsAnchor(null)}>
						<MenuItem onClick={(event)=>{setAccountSettingsAnchor(null);doTakeStock()}}>
							Take Stock
						</MenuItem>
						<MenuItem onClick={(event)=>{setAccountSettingsAnchor(null);doReceiveStock();}}>
							Receive Stock
						</MenuItem>
						<MenuItem onClick={(event)=>{setAccountSettingsAnchor(null);doReportUsage();}}>
							Report Usage
						</MenuItem>
						<MenuItem onClick={(event)=>{setAccountSettingsAnchor(null);doProfile();}}>
							Profile
						</MenuItem>
						<MenuItem onClick={(event)=>{setAccountSettingsAnchor(null);doLogout();}}>
							Logout
						</MenuItem>
					</Menu>
					<Container maxWidth={false} disableGutters={true} sx={{maxHeight:window.innerHeight-100, overflowY:"scroll"}}>
						{props.children}
					</Container>
				</Stack>
			</Grid>
		</Grid>
		</Dialogs>
	);
};
export default React.memo(DefaultLayout);
