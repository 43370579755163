// vim: ts=2
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Dialog, Container, Typography, Stack, Box, Button, Grid, TablePagination, Paper, IconButton, Menu, MenuItem, TextField } from "@mui/material";
import { InStockHeader, InStockButton, InStockSubHeader } from "./../Components/Styled";
import { Download } from "@mui/icons-material";
import DataTable from "./../Components/DataTable/DataTable";
import DefaultLayout from "./../Layouts/DefaultLayout";
import LoadingSpinner from "./../Components/LoadingSpinner/LoadingSpinner";
import FreeTrialBanner from "./../Components/FreeTrialBanner/FreeTrialBanner";
import StockAlertWidget from "./../Components/StockAlertWidget/StockAlertWidget";
import NewStockWidget from "./../Components/NewStockWidget/NewStockWidget";
import TableToolsDialog from "./../Components/TableToolsDialog/TableToolsDialog";
import TakeStockDialog from "./../Components/TakeStockDialog/TakeStockDialog";
import DraftSelectionDialog from "./../Components/DraftSelectionDialog/DraftSelectionDialog";
import Dialogs from "./../Containers/Dialogs/Dialogs";
import UserContext from "./../Context/UserContext";
const StockPage = (props) => {
	const navigate = useNavigate();
	const impersonate = props.impersonate === undefined ? false : props.impersonate; // admin user is impersonating another user 
	const [stock, setStock] = useState(null);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [moreActions, setMoreActions] = useState(false);
	const [receiveStock, setReceiveStock] = useState(false);
	const [reportUsage, setReportUsage] = useState(false);
	const [takeStock, setTakeStock] = useState(false);
	const [viewStockTakes, setViewStockTakes] = useState(false);
	const [selectedHeader, setSelectedHeader] = useState(null);
	const [searchTerm, setSearchTerm] = useState("");
	const [filterTerm, setFilterTerm] = useState("");
	const [page, setPage] = useState(0);
	const [sort, setSort] = useState(null); // sorting state, clicking table headers manipulates this
	const context = useContext(UserContext);
	const loading = stock === null;
	const [showStockDialog, setShowStockDialog] = useState(false);
	const [download, setDownload] = useState(false);
	const [fileToDownload, setFileToDownload] = useState("");
	const doExport = () => {
		const headers = [
			"name",
			"quantity_description",
			"available_quantity",
			"recent_usage",
			"category",
			"stocktaking_locations",
			"under_replenish_level"
		];
		const mapper = (e, i) => {
			return {
				"name": e.name,
				"quantity_description": e.quantity_description,
				"available_quantity": `${e.available_quantity} ${e.units}`,
				"recent_usage": 0,
				"category": e.category.name,
				"stocktaking_locations": e.stocktaking_tags.join(", "),
				"under_replenish_level": e.under_replenish_level ? "Yes" : "No"
			};
		};
		const records = stock.map(mapper);
		const payload = {
			"headers": headers,
			"records": records
		};
		const s = (response) => {
			const data = response.data;
			toast.success("Export generated, please click on the link to download it.");
			setFileToDownload(data);
			setDownload(true);
		};
		const e = (error) => {
			toast.error("Failed to generate export, please contact support.");
		};
		context.api.getDataExport(context, s, e, payload);
	};
	// handle changes of location
	const onLocationChanged = (location) => {
		toast.info(`Location changed, reloading stock ...`);
		setStock(null);
	};
	// show add stock dialog
	const showDialog = () => { 
		setShowStockDialog(true);
	};
	const onSearchChanged = (phrase) => {
		// clearing the search phrase is the same as clearing search results
		if(phrase === ""){
			setStock([...context.stock]);
			return;
		}
		const upper = phrase.toUpperCase();
		const matches = context.stock.filter((x)=>{ return x.name.toUpperCase().indexOf(upper) !== -1 });
		setStock(matches);
	};
	const onFilterValueChanged = (filter, value) => {
		setFilterTerm(value);
		if(filter === "" || value === "None" || value === ""){
			setStock([...context.stock]);
			return;
		}
		// filters don't directly map to fields
		// need to use a function to get the value from the data object
		// add to this map to implement new filters
		const mapping = {
			"Category": (item, value) => { return item.category.name === value },
			"Stocktaking Tags": (item, value) => { return item.stocktaking_tags.join(", ").indexOf(value) !== -1 },
			"Stock Alert": (item, value) => { return item.under_replenish_level ? value === "Yes" : !item.under_replenish_level ? value === "No" : false  }
		};	
		const f = mapping[filter];
		const matches = context.stock.filter((x)=>{ return f(x, value) });
		setStock(matches);
	};
	const onPageChanged = (event, page) => {
		setPage(page);
	};
	const onRowClicked = (event, row) => {
		const item = stock.find((x)=>{ return x.id === row.id });
		if(item === undefined){
			toast.error(`Failed to find stock item for id ${row.id}`);
			return;
		}
		context.stockItem = item;
		if(!impersonate){
			navigate(`/stock-items/${row.id}`);	
			return;
		}else{
			navigate(`/accounts/impersonate/stock-items/${row.id}`);
		}
	};
	const applySort = (headerName, sortState) => {
		const s = sortState[headerName];
		const comparators = {
			"Name": ( a, b ) => { return s.mode === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name) }
		};	
		const copy = [...context.stock];
		copy.sort(comparators[headerName]);
		setStock(copy);
	};
	const onSortClicked = (event, headerName) => {
		const copy = {...sort};
		const sortable = Object.keys(copy);
		const s = sortable.find((x)=>{ return x === headerName });
		if(s === undefined){
			toast.info(`Error, column ${headerName} is not sortable.`);
			return;
		}
		const sortState = copy[headerName];
		if(!sortState.sorted){
			sortState.sorted = true;
			sortState.mode = "asc";
			setSort(copy);
			applySort(headerName, copy);
			return;
		}
		sortState.mode = sortState.mode === "asc" ? "desc" : "asc";
		setSort(copy);
		applySort(headerName, copy);
	};
	const onHeaderClicked = (event, headerName) => {
		const config = {
			searchable: ["Name"],
			filterable: ["Category", "Stocktaking Tags", "Stock Alert"]
		};
		context.menuAnchor = event.target;
		// determine if searchable or filterable
		// once either of these actions has been checked
		// set selected header causing rendering ...
		const searching = config.searchable.find((x)=>{ return x === headerName });
		if(searching !== undefined){
			context.searchingTable = true;
			context.filteringTable = false;
			setSelectedHeader(headerName);
			return;
		}
		const filtering = config.filterable.find((x)=>{ return x === headerName });
		if(filtering !== undefined){
			context.filteringTable = true;
			context.searchingTable = false;
			setSelectedHeader(headerName);
			return;
		}
		context.filteringTable = false;
		context.searchingTable = false;
		setSelectedHeader(null);
	};
	const getUniqueCategories = (stock) => {
		if(!context.setupComplete){
			return [];
		}
		return context.stockCategories.map((e,i)=>{ return e.name });
	};
	const getUniqueStocktakingTags = (stock) => {
		if(!context.setupComplete){
			return [];
		}
		return context.stocktakingLocations.map((e,i)=>{ return e.name });
	};
	const getFilters = (stock) => {
		return { 
			"Category": [...getUniqueCategories(stock), "None" ],
			"Stocktaking Tags": [ ...getUniqueStocktakingTags(stock), "None" ],
			"Stock Alert": ["Yes", "No", "None"]
		};
	};
	useEffect(()=>{
		if(stock !== null){
			return;		
		}
		const s = (response) => {
			const data = response.data.map((e,i)=>{ return {...e, key: e.id}});
			context.stock = data;
			setStock(data);
			const sort = {
				"Name": { sorted: false, mode: "none" }	
			};
			setSort(sort);
		};
		const e = (error) => {
			toast.error("Failed to get sales items from backend, please contact support.");
		};
		// only call this once user has setup their account
		// i.e. has locations, otherwise selected location cannot exist 
		if(!context.setupComplete){
			setStock([]);
			return;
		}
		if(!impersonate){
			context.api.getStockItems(context, s, e);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/stock-items`,
      "method": "GET"
		};
		context.api.doImpersonate(context, s, e, payload);
	});
	let content = <LoadingSpinner height={"100px"} message="Loading stock, please wait ..."/>;
	if(!context.setupComplete){
		content = (
			<Box sx={{textAlign:"center", borderRadius:"10px", border:"1px solid #CDCDCD", padding:"15px"}}>
				<Stack spacing={2}>
					<InStockHeader>Please complete your account setup before viewing stock items.</InStockHeader>
					<InStockSubHeader>To setup your account please visit the <InStockButton onClick={()=>{navigate('/settings');}}>settings</InStockButton> page.</InStockSubHeader>
				</Stack>
			</Box>
		);
	}
	if(!loading && context.setupComplete){
		const offset = page * itemsPerPage;
		let paginated = stock.slice(offset, offset + itemsPerPage);
		const fields = [
			{ field: "name", headerName:"Name", valueGetter: (params)=>{return params.row.name }, width:200 },
			{ field: "quantity_description", headerName:"Description", width: 200 },
			{ field: "available_quantity", headerName:"Quantity on Hand", width: 200, valueGetter: (params)=>{return `${params.row.available_quantity} ${params.row.units}`} },
			{ field: "rolling_usage_7_days", headerName:"7 Days Usage", width: 150 },
			{ field: "category", headerName:"Category", valueGetter: (params)=>{return params.row.category.name; }, width:150 },
			{ field: "stocktaking_locations", headerName:"Stocktaking Tags", valueGetter: (params)=>{ return params.row.stocktaking_tags.join(", "); }, width:150 },
			{ field: "under_replenish_level", headerName:"Stock Alert", valueGetter: (params)=> { return params.row.under_replenish_level ? "Yes" : "No"}} 
		];
		const filters = getFilters(context.stock);
		let menuAction = null;
		if(context.searchingTable){
			menuAction = (
				<Box sx={{padding:"15px", border:"1px solid #CDCDCD"}}>
					<TextField value={searchTerm} label="Search ..." size="small" 
						onChange={(event)=>{setSearchTerm(event.target.value);}} 
						onBlur={()=>{onSearchChanged(searchTerm);}} />
				</Box>
			);
		}
		if(context.filteringTable){
			menuAction = (	
				<Box sx={{padding:"15px", border:"1px solid #CDCDCD", width:"200px"}}>
					<TextField select 
							fullWidth
							value={filterTerm} 
							label="Filter by ..." 
							size="small" 
							onChange={(event)=>{onFilterValueChanged(selectedHeader, event.target.value);}}>
						{filters[selectedHeader].map((e,i)=>{ return <MenuItem key={e} value={e}>{e}</MenuItem> })}
					</TextField>
				</Box>
			);
		}
		content = (
			<Stack spacing={2}>
				<Box sx={{backgroundColor:"inherit"}}>
					<Button onClick={()=>{setReceiveStock(true);}} 
							size="large" 
							variant="contained" 
							className="pg"
							sx={{color:"white", fontWeight:"bold", mr:"15px"}}>
						Receive Stock
					</Button>
					<Button onClick={()=>{setTakeStock(true);}} 
							size="large" 
							variant="contained" 
							className="pg" 
							sx={{color:"white", fontWeight:"bold", mr:"15px"}}>
						Take Stock
					</Button>
					<Button size="large"
							className="pg" 
							sx={{color:"white", fontWeight:"bold", mr:"15px"}} 
							onClick={showDialog}>
						New Stock Item
					</Button>
					<Button size="large"
							className="pg" 
							sx={{color:"white", fontWeight:"bold", mr:"15px"}} 
							onClick={()=>{setViewStockTakes(true);}}>
						View Stocktakes
					</Button>
					<Button size="large"
							className="pg" 
							sx={{color:"white", fontWeight:"bold", mr:"15px"}} 
							onClick={()=>{setReportUsage(true);}}>
						Report Usage
					</Button>
				</Box>
				<Box>
					<Grid container spacing={4}>
						<Grid item lg={6} xs={12} sm={12}>
							<NewStockWidget stock={context.stock} limit={7} />
						</Grid>
						<Grid item lg={6} xs={12} sm={12}>
							<StockAlertWidget stock={context.stock} />
						</Grid>
					</Grid>	
				</Box>
				<Paper elevation={3} sx={{padding:"25px"}}>
				<Stack spacing={2}>
				<Grid container>
					<Grid item sm={10}>
						<Typography variant={"h5"} sx={{color:"#C12393", fontWeight:"bold", fontSize:"25px"}}>Stock items at {context.selectedLocation.name}</Typography>
					</Grid>		
					<Grid item sm={2} sx={{textAlign:"right"}}>
						<IconButton onClick={doExport}>
							<Download sx={{color:"black", fontSize:"2rem"}} />	
						</IconButton>	
					</Grid>
				</Grid>
				<Box sx={{backgroundColor:"inherit"}}>
					<Typography variant={"body"} sx={{color:"#5E085A", fontSize:"20px", verticalAlign:"middle"}}>{stock.length} RESULT(S)</Typography>
				</Box>
				<Box sx={{width:"100%"}}>
					<DataTable rows={paginated} 
						columns={fields} 
						onRowClicked={onRowClicked} 
						onHeaderClicked={onHeaderClicked} 
						onSortClicked={onSortClicked} 
						sort={sort} 
						filters={filters} />
					<Box sx={{float:"right"}}>
						<TablePagination component={Box} count={stock.length} 
							onPageChange={onPageChanged} page={page} 
							rowsPerPage={itemsPerPage} 
							onRowsPerPageChange={(event)=>{setItemsPerPage(event.target.value);}} />
					</Box>
				</Box>
				</Stack>
				</Paper>
				{/* Search menu ...*/}
				<Menu open={selectedHeader !== null} 
						anchorEl={context.menuAnchor} 
						id="search-menu" 
						onClose={(event)=>{ context.filteringTable=false; context.searchingTable=false; setSelectedHeader(null); }}>
					{menuAction}
				</Menu>
			</Stack>
		); // end content
	} // loaded and setup complete
	let response = (
		<Dialogs 
			impersonate={impersonate}	
			download={download}
			fileToDownload={fileToDownload}
			onDownloadClosed={()=>{setDownload(false);}}
			takeStockDraft={takeStock}
			receiveStockDraft={receiveStock}
			reportUsage={reportUsage}
			viewStockTakes={viewStockTakes}
			addStock={showStockDialog} 
			onReportUsage={()=>{setReportUsage(false);setStock(null);}}
			onReportUsageClosed={()=>{setReportUsage(false);}}	
			onStockCloseClicked={(event)=>{setShowStockDialog(false);}} 
			onStockAdded={(event)=>{setStock(null);}} 
			onTakeStockClosed={()=>{setTakeStock(false);}}
			onReceiveStockClosed={()=>{setReceiveStock(false);}}
			onReceiveStockDraftClosed={()=>{setReceiveStock(false);}}
			onTakeStockDraftClosed={()=>{setTakeStock(false);}}
			onViewStockTakesClosed={()=>{setViewStockTakes(false);}}
			onDraftFinalised={()=>{setStock(null);}}>
				<Box sx={{padding:"15px"}}>
					{content}
				</Box>
		</Dialogs>
	);
	if(impersonate){
		return response;
	}
	// regular user is viewing their own data
	return (
		<DefaultLayout onDraftFinalised={()=>{setStock(null);}} onLocationChanged={onLocationChanged}>
			{response}	
		</DefaultLayout>
	);
};
export default React.memo(StockPage);
