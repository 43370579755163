import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { Grid, Card, CardContent, Typography, Box, Stack, CardActions, IconButton } from "@mui/material";  
import { Refresh } from "@mui/icons-material";
import UserContext from "./../../Context/UserContext";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";
export const SalesItemCount = () => {
	const context = useContext(UserContext);
	const [data, setData] = useState(null);
	useEffect(()=>{
		if(data !== null){
			return;
		}
		const s = (response) => { const data = response.data; setData(data.count); };
		const e = (error) => { toast.error("Failed to get location count, please contact support"); };
		context.api.getSalesItemCount(s, e);
	});
	return (
		<Counter name={"Total Sales Items"} data={data} onRefreshClicked={()=>{setData(null);}}/>	
	);
};
export const StockItemCount =  () => {
	const context = useContext(UserContext);
	const [data, setData] = useState(null);
	useEffect(()=>{
		if(data !== null){
			return;
		}
		const s = (response) => { const data = response.data; setData(data.count); };
		const e = (error) => { toast.error("Failed to get location count, please contact support"); };
		context.api.getStockItemCount(s, e);
	});
	return (
		<Counter name={"Total Stock Item"} data={data} onRefreshClicked={()=>{setData(null);}}/>	
	);
};
export const LocationCount = (props) => {
	const context = useContext(UserContext);
	const [data, setData] = useState(null);
	useEffect(()=>{
		if(data !== null){
			return;
		}
		const s = (response) => { const data = response.data; setData(data.count); };
		const e = (error) => { toast.error("Failed to get location count, please contact support"); };
		context.api.getLocationCount(s, e);
	});
	return (
		<Counter name={"Total Locations"} data={data} onRefreshClicked={()=>{setData(null);}}/>	
	);
};
export const CustomerCount = (props) => {
	const context = useContext(UserContext);
	const [data, setData] = useState(null);
	useEffect(()=>{
		if(data !== null){	
			return;
		}
		const s = (response) => { const data = response.data; setData(data.count); };
		const e = (error) => { toast.error("Failed to get customer count, please contact support"); };
		context.api.getUserCount(s, e, "customer");
	});
	return (
		<Counter name={"Total Customers"} data={data} onRefreshClicked={()=>{setData(null);}}/>	
	);
};
const Counter = (props) => {
	const name = props.name;
	const data = props.data;
	let content = <LoadingSpinner/>;
	if(data !== null){
		content = (
			<Box sx={{textAlign:"center"}}> 
				<Stack spacing={3}>
					<Typography sx={{fontSize:"25px", fontWeight:"bold", color:"black"}}>
						{name}
					</Typography>
					<Typography sx={{fontSize:"30px", fontWeight:"bold", color:"black"}}>
						{data}
					</Typography>
				</Stack>
			</Box>
		);
	}	
	return (
		<Grid item lg={4} sm={12}>
			<Box sx={{padding:"15px"}}>
			<Card fullWidth raised={true}>
				<CardContent>
					{content}
				</CardContent>		
				<CardActions>
					<IconButton  onClick={props.onRefreshClicked}><Refresh sx={{color:"black"}}/></IconButton>
				</CardActions>
			</Card>
			</Box>
		</Grid>	
	);
};
